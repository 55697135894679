<template>
  <div class="container" lang="es">
    <main class="content" v-bind:class="[{start: pageInit}, whichStepIsActive ]">
      <h1 class="contentTitle">Licitación de diseño en 6 pasos</h1>
      <!-- titles for the process -->
      <div class="licContainer licHeader licHeader1" @click="closeModal()"
      v-bind:class="makeActiveCompany">
      <img src="/static/img/icon_industry.svg" alt=""><h2>Organiza&shy;ción man&shy;dan&shy;te</h2></div>
      <div class="licContainer licHeader licHeader2 chevron" style="text-align:center" @click="closeModal()"
      ><router-link to="/">
      	<img src="/static/img/chd_logo.svg" alt="">
      </router-link>
      </div>
      <div class="licContainer licHeader licHeader3" @click="closeModal()"
      v-bind:class="makeActiveDesign">
      <img src="/static/img/icon_design.svg" alt=""><h2>
      Empresas de&nbsp;diseño</h2></div>

      <!-- step builder -->

      <template v-for="step in steps">
	      <transition
			  name="move"
			  mode="out-in"
				  >
 
      	<div @click="highlightStep(step)" :class="'licContainer '+'stepTitle '+'licPos-'+ step.metadata.namePos+'2 '+'chevron hyphenate'">
          <h1>{{step.order}}</h1>
          <h2>{{step.title}}</h2>
        </div>
    </transition>
   		
        <template v-for="entity in stepCompany(step._id)" >

              <div v-bind:class="['licContainer licCompany '+'licPos-'+step.metadata.namePos+'1 '+'animatebg ', isEntityActive(entity), isEntityHighlighted(entity)]" :style="'background-color:'+entity.metadata.color"  >
                  <a @click="showModal(step,entity,entity._id)">
                    <h3>{{entity.title}}</h3>
                  </a>          
              </div>

        </template>
        <template v-for="entity in stepDesigner(step._id)" >      

            <div v-bind:class="['licContainer licDesign '+'licPos-'+step.metadata.namePos+'3 '+'animatebg ',isEntityActive(entity),isEntityHighlighted(entity)]" :style="'background-color:'+entity.metadata.color" v-if="entity.metadata.type == 1">
                <a @click="showModal(step,entity,entity._id)" v-bind:class="{ negative: entity.metadata.negative}">
                  <h3>{{entity.title}}</h3>
                </a>          
            </div>

    		</template>
      </template >
        <modal v-show="isModalVisible" @close="closeModal" v-bind:step="step" v-bind:entity="entity" v-bind:entity_details="entity_details"
        v-bind:detail_item="detail_item"
		/>
        

        <div class="downloadPDF hyphenate desktop-only" :class="{inactive: isModalVisible}">
        <a href="/static/pdf/chilediseno_licitaciones_V002.pdf" download="Guia de Proceso de licitación">Baja este manual<br>en formato PDF</a>
        </div>
        <!-- social icons for desktop -->
        <div class="socialmedia " :class="{inactive: isModalVisible}">
          <a target="_blank" href="http://www.facebook.com/sharer.php?s=100&p[title]=Revisa%20la%20gu%C3%ADa%20de%20proceso%20de%20licitaci%C3%B3n%20de%20dise%C3%B1o%20de%20Chile%20Dise%C3%B1o&p[url]=https://licitaciones.chilediseno.org"><i class="icon-facebook-circled"></i></a>
          <a target="_blank" href="https://twitter.com/intent/tweet?source=webclient&text=Revisa%20la%20gu%C3%ADa%20de%20proceso%20de%20licitaci%C3%B3n%20de%20dise%C3%B1o%20de%20Chile%20Dise%C3%B1o&url=https://licitaciones.chilediseno.org"><i class="icon-twitter-circled"></i></a>
          <a target="_blank" href="whatsapp://send?text=Revisa%20la%20gu%C3%ADa%20de%20proceso%20de%20licitaci%C3%B3n%20de%20dise%C3%B1o%20de%20Chile%20Dise%C3%B1o%20>%20https://licitaciones.chilediseno.org " data-action="share/whatsapp/share"><i class="icon-comment"></i></a>
        </div>
        </main>
      </div>


</template>

<style lang="scss" lang="scss">
  @import '../scss/atoms/variables.scss';
  @import '../scss/atoms/grid.scss';
  @import '../scss/atoms/chevron.scss';
  @import '../scss/atoms/lic_container.scss';  
  @import '../scss/atoms/explanation.scss';
  @import '../scss/vendors/icons_fontello.scss';
  @import '../scss/atoms/icons.scss'; 
  @import '../scss/atoms/bounce.scss';


</style>

<script>
 import modal from '@/components/Modal';

 // importing the data for the website based on:
 // https://stackoverflow.com/questions/38249362/vue-js-external-data-object
// Objects imported from cosmicjs account 
 import { objects } from '@/data/objects.json';
 // import { entities } from '@/data/data.json';

export default {
	name: 'licitaciones',
    components: {
      modal,
    },

  data:() => ({
   isModalVisible: false,
   pageInit: true,
   objects: objects,
    step: {},
    // set a default entity, in order to eliminate console error.
    entity: objects[0],
    // set an empty detail_item
    detail_item:{},
    entity_details:[],
   currentEntity: {},
   // Highlight entities when step is clicked
    highlightedstep: {},

  }),
  // filter results based on objects
  computed:{
  	steps: function(){
  		return this.objects.filter(function(objects){
  			return objects.type_slug == 'steps'
  		})
  	},
  	entities: function(){
		return this.objects.filter(function(objects){
  			return objects.type_slug == 'entities'
  		})
     },
    companies: function(){
    return this.entities.filter(function(entities){
        return entities.metadata.type == 0
      })
     },
    designers: function(){
    return this.entities.filter(function(entities){
        return entities.metadata.type == 1
      })
     },
    entities_details: function(){
    return this.objects.filter(function(objects){
        return objects.type_slug == 'entity-details'
      })
     },
    makeActiveCompany: function(){
    	return {
    		active: !this.entity.metadata.type && this.isModalVisible
    	}
    },
    makeActiveDesign: function(){
    	return {
    	active: this.entity.metadata.type && this.isModalVisible
    	}
    },
    whichStepIsActive: function(){
      if(this.step|this.isModalVisible){
        return 'stepSelected'+this.step.order
      }
    }

  },
  methods: {

//  filter stepEntities comes from answer at https://stackoverflow.com/a/50343528, I had to convert it to a method because it was giving an infinite loop as computed. this is the way to fileter by id    

    stepEntities(theStep){
      return this.entities.filter(function(entity) {
      return theStep === entity.metadata.stepid._id;
      });
    }, 
    stepCompany(theStep){
      return this.companies.filter(function(entity) {
      return theStep === entity.metadata.stepid._id;
      });
    },
    stepDesigner(theStep){
      return this.designers.filter(function(entity) {
      return theStep === entity.metadata.stepid._id;
      });
    },
    entityDetails(entity){
      return this.entities_details.filter(function(entities_details) {
      return entity._id === entities_details.metadata.entityId._id;
      });
    }, 
    isEntityActive(current){
    	if(this.entity){
	    	return {
	    		active: current._id == this.entity._id
	    	}
    	}
    },
    isEntityHighlighted(current){
      if(this.entity){
        return {
          highlighted: current.metadata.stepid._id == this.highlightedstep._id
        }
      }
    },
    highlightStep(step){
      this.highlightedstep = step;
        setTimeout(
    _ => this.highlightedstep = false, // enable the input
    250 // after 05 second
  )
    },
    started(){
    	return this.pageInit = false;
    },
    startedAgain(){
    	return this.pageInit = true;
    	return this.entity._id = 0;
    },     
    showModal(step,entity) {
        this.isModalVisible = true;
        this.step = step;
        this.entity = entity;
        this.detail_item = this.objects[0];
        this.detail_item.active = false;
        this.entity_details = [];
        this.entity_details = this.entityDetails(this.entity);

        // once the modal is activated the page is no longer in pageinitmode
        if(this.pageInit){
        this.started();
    	};

        // google Analytics tracking 
        // define internal variables
        // this.$ua.trackEvent( eventCategory: string,eventAction: string, eventLabel: string, eventValue: number)
        this.modaleventcategory = 'Paso';
        this.modalenventlabel = 'Paso '+ this.step.order +': '+ this.whatIsIt() +': '+ this.entity.title;
        // track the event
        this.$ua.trackEvent(this.modaleventcategory,
        	"Abrir",this.modalenventlabel,1);
       // this.log(this.modalenventlabel);
    	

      },
      closeModal() {
      	this.entity = this.objects[0];
      	this.detail_item = this.objects[0];
        this.isModalVisible = false;
        this.startedAgain();
      },
          log(e) {
      console.log(e);
      },
      whatIsIt(){
        if(this.entity.metadata.type){return 'Emp. de diseño'};
        if(!this.entity.metadata.type){return 'Emp. Mandante'};
      }
      // methods closing
    },
}

</script>