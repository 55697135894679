<template name="modal-template">
  <transition name="modal-fade">
    <div class="modal-backdrop " 
    :style="'background-color:'+entity.metadata.color"
    v-bind:class="[whichType, 'step-'+entity.metadata.stepid.metadata.namePos]">
      <div class="modal"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
        v-bind:class="{ negative: entity.metadata.negative}"
      >
        <header
          class="modal-header"
          id="modalTitle"
          lang="es"
        >
          <slot name="header">
            <h1>{{step.order}}</h1>
            <h2 class="hyphenate"><span class="title">{{step.title}}</span><br/>
            <span class="subtitle">{{entity.title}}</span></h2>
            <button
              type="button"
              class="btn-close"
              @click="close"
              aria-label="Close modal"
            >
              &times;
            </button>
          </slot>
        </header>


      <h3 class="entityDetailsTitle">Acciones sugeridas</h3>
        <dl class="detail_items" lang="es">
          <div class="detail_line" v-bind:class="isStepActive "></div>

          <!-- second level of information -->

          <template v-for="detail_item in detailed_pairs"  >
          <dt v-bind:class="[ 'detail_letter detail_letter_pos_'+detail_item.letter,  isDetailActive(detail_item) ] "

             @click="detailMakeActive(detail_item)">
            {{detail_item.letter}}


            </dt>
          <dd 
          :class="'detail_txt detail_txt_'+detail_item.letter+' hyphenate'" 
          @click="detailMakeActive(detail_item)"
            >
            {{detail_item.entity_det.title}}</dd>
          </template>

        <!-- third level of information -->
        <div class="detail_content hyphenate" v-bind:class="isAnyDetailActive" >
          <h3 class="detail_txt">{{detail_item_title}}</h3>
          <div v-html="detail_item_content"></div>
        </div>
        
        </dl>



      </div>
    </div>
  </transition>
</template>

<script>
  export default {
    template: "#modal-template",
    name: 'modal',
    props:['step', 'entity','entity_details'],
    data:function(){
      return{
          letters: ['a','b','c'],
          step_active: 0,
          detail_item: {},
          detail_item_title: "",
          detail_item_content: "",
      }
    },
    computed: {
      // https://forum.vuejs.org/t/v-for-access-2-or-more-arrays-in-same-level/5224/2
      detailed_pairs:function() {
        return this.entity_details.map((entity_detail, i) => {
          return {
            letter: this.letters[i],
            entity_det: entity_detail,
            active: false,
          }
        })
      },
      isStepActive:function(){
        return{
          active: this.step_active == this.step._id
        }
        
      },
      isAnyDetailActive:function(){

        if(this.detail_item.entity_det){
            return{
            active: this.step_active == this.step._id && this.detail_item.active && this.detail_item.entity_det.metadata.entityId._id == this.entity._id
          } 
        }

      },
      whichType:function(){
        return{
          design: this.entity.metadata.type,
          company: !this.entity.metadata.type, 
        }

      }


    },
    methods: { 
      close() {
        this.$emit('close');
        return this.detail_item = {};
      },
      detailMakeActive(detail_item){
        this.detail_item = detail_item;
        // this.detail_item.active = true;
        // this.$set(this.detail_item, 'active',true);
        this.step_active = this.step._id;

        this.detail_item_title = detail_item.entity_det.title;
        this.detail_item_content = detail_item.entity_det.content;
        // google Analytics tracking 
        // this.$ua.trackEvent( eventCategory: string,eventAction: string, eventLabel: string, eventValue: number)
        this.modaldetaillabel ='Paso '+this.step.order+': '+this.whatIsIt()+': '+ detail_item.entity_det.title;

        // track the event
        this.$ua.trackEvent('detalle',
          "Abrir",this.modaldetaillabel,1)
           // this.log(this.modaldetaillabel);

        return this.detail_item.active = true;


      },
      detailMakeActiveTxt(detail_item){
        this.detail_item = detail_item;
        // this.detail_item.active = true;
        // this.$set(this.detail_item, 'active',true);
        this.step_active = this.step._id;
        return this.detail_item.active = true;
        
      },
      isDetailActive(current){
        return {
        active: this.detail_item.active && current.entity_det._id == this.detail_item.entity_det._id
        }     
      },
      log(e) {
      console.log(e);
      },

      whatIsIt(){
        if(this.entity.metadata.type){return 'Emp. de diseño'};
        if(!this.entity.metadata.type){return 'Emp. Mandante'};
      }
    },
  };
</script>

<style lang="scss" lang="scss">
  @import '../scss/atoms/variables.scss';

$leftcolumn: 2.25rem;

$rootsize: 16px;
// One grid unit.
$grid-unit: $baseline; // ~ 20px
// Information about the font. Cap-height calculated using https://codepen.io/MisterCurtis/pen/doEzzx
$font: (
    cap-height: 0.705,
    font-family: $sanserif
);


.modal-backdrop {

  z-index: 10;
  grid-area: 3 / 2 / -1 / -1 ;
    @media screen and (min-width: $break-normal) and (orientation : landscape ){
       padding-top: $baseline + $gridgap;
       box-shadow:  ($gridgap)*-1 0 0 #fff;
       grid-area: modal;
       z-index: 30;
    }
  &.design{
    grid-area: 3 / 1 / -1 / 3 ;
      @media screen and (min-width: $break-normal) and (orientation : landscape ){
       box-shadow:  ($gridgap)*-1 0 0 #fff;
       grid-area: modal;
    }
  }
  background-color: white;
  /*hide the bottom of the last chevron*/
  box-shadow: 0 $baseline 0 #fff;
  padding-top: $baseline ;

  border-radius: 3px;

  margin-bottom: $gridgap;


}
.modal{
  position: relative;
  // grid definitions 
  display:grid;
  grid-template-columns:1fr;
  grid-template-rows: 4*$baseline repeat(3, 2*$baseline) 1fr;
  grid-template-areas:
    "header"
    "content"
    "content"
    "content"
    "content"
    ;
    height: 100%;
  // font definitions
}

header{
  // position relative to parent
  position: relative;
  grid-area: header;
  padding-left: $boxinnerpadding; 
  
  // positioning o inner objects;
  display: flex;
  flex-direction:row;

  .btn-close{
    border:0;
    padding: 0;
    position: absolute;
    top:-1.22rem;
    right: $boxinnerpadding;
    @include baseline(45,40);
    background-color: transparent;
    font-weight: 100;

    // if is a company it should be black
    color: rgba(0, 0, 0, 0.5);
    .design &{
      color: white;
    }
    .negative &{
      color: rgba(255, 255, 255, 0.5);
    }


  }

  h1{
    width: $leftcolumn;
    overflow: hidden;
    @include baseline(50,40);
    font-weight: 100;
    text-align: center
  }
  h2{
    display: block;
    margin:0 $boxinnerpadding;
    max-width: 10rem;
    @include baseline(14,20);
    font-weight: 400;
    @include choosefont();
    span.title{
      font-weight: 700;
      text-transform: uppercase;
    }
    span.subtitle{

    }
  }
}
.entityDetailsTitle{
  // positioning within the grid
  position: absolute;
  bottom: -2.5rem ;
  left: $baseline;
  // font definition;
  @include baseline(14,20);
  text-transform: uppercase;
  font-weight: 400;
  transform: rotate(-90deg);
  transform-origin: -1.35rem -0.75rem 0;
  z-index: 10;
// small phone 
@media screen and (max-height: $break-v-xsmall) and (orientation : portrait){
    font-size: 0.7rem;
}
  // if is a company it should be black
  color: rgba(0, 0, 0, 0.5);
    .design &{
      color: white;
    }
}

.detail_items{
  position: relative;
  grid-area:content;
  // grid definitions 
  display:grid;
  grid-template-columns: $leftcolumn+$boxinnerpadding 1fr;
  grid-template-rows: repeat(3, auto) 1fr;
  grid-template-areas:
    "a      contenta"
    "b      contentb"
    "c      contentc"
    "d      contentd"
    ;
    height: 100%;
    // eliminate a vertical line caused by the background of the content
    padding-right: 1px;
    padding-bottom: 1px;
  // font definitions 

}
// hover effects only for medium resolution

@media screen and (min-width: $break-normal) and (orientation : landscape) {

  .detail_letter, .detail_txt {
    &:hover{
      background-color: #0066FF;
      color: white;
      cursor:pointer;
    }
    &.active, .active &{
      &:hover{
        background-color: transparent;
        color: inherit;
        cursor: auto;
      }
    }
  }
}

.detail_letter{
    @include baseline(25,20);
    z-index:10;
    font-weight: 100;
    text-transform: uppercase;
    text-align: center;
    margin-top:0;
    padding-top:0.66094rem;
    padding-left:$boxinnerpadding;
    height: 2.5rem;
    &.active {
    border:1px solid white;
    padding-top:calc(0.66094rem - 1px);
    padding-left:calc(0.43375rem - 3px);
    border-left:3px solid white;
    border-right-color: transparent;
    box-shadow: 1px 0 0 #c4c828;
    font-weight: 400;
    .company.step-a &{
      border:1px solid #bbb;
      border-left:3px solid #bbb;
      border-right-color: transparent;
    }
  }
}

// now set the color for the shadow on active and the detail content.
$companytype: company design design;
$positions: a b c d e f g;
$activestep-slug: ".step";
$activeclass-slug: " .detail_letter.active";
$detailcontent-slug: " .detail_content";
$colorsCompany: #ededee #dedfe0 #d1d3d4 #c4c6c8 #b6b8ba #a7a9ac #a7a9ac;
$colorsDesign: #c5c828 #eecd17 #f99d1c #e25b28 #da1c5c #9e1f63 #9e1f63;

// Given two types of entities
@for $t from 0 to 2 {
  // and a number of colors
  @for $c from 0 to 7{
// build the class name .design .step-1 .detail_letter.active
    .#{nth($companytype, $t+1)}#{$activestep-slug}-#{nth($positions, $c+1)}{

        #{$activeclass-slug}{

            //  if is a company
            @if $t == 0{
                  box-shadow: 1px 0 0 nth($colorsCompany, $c+1);

            }
            //  if is a design shop
            @if $t == 1{
                  box-shadow: 1px 0 0 nth($colorsDesign, $c+1);
            }

      }
        #{$detailcontent-slug}{

            //  if this is a company
            @if $t == 0{
                  background-color: nth($colorsCompany, $c+1);

            }
            //  if this is a design shop
            @if $t == 1{
                  background-color: nth($colorsDesign, $c+1);
            }
      }      
    }
  }
}

.detail_txt, .detail_content{
  padding-left:$boxinnerpadding;
  padding-right:$boxinnerpadding;
}

.detail_txt{
    padding-left:$boxinnerpadding;
    padding-right:$boxinnerpadding;
      // Raise this element above the line element
    z-index:10;
    @include baseline(14,20);
    font-weight: 500;
    margin-bottom: 0.92rem;
    @include choosefont();
}
dd.detail_txt{
      text-decoration: underline;
      font-weight: 400;

}
// third level of content
.detail_line, .detail_content{
  position: absolute;
  grid-area: 1 / 2 / -1 / -1;
  width:100%;
  height: 100%;
}

.detail_line{
  z-index:10;

  &.active{
    border-left:1px solid white;
    border-top:1px dotted white;
    .company.step-a &{
      border-color: #bbb;
    }
  }
}
.detail_content{
  display: none;
  padding-left:calc( #{$boxinnerpadding} - 1px);
  padding-right:calc( #{$boxinnerpadding} - 1px);
  margin:1px;
  z-index:20;
  @include baseline(13,16);
  font-weight: 400;
  @include choosefont();
  
  overflow-y: scroll;

  &.active{
    display: block;
  }
  h4{
    @include baseline(11,16);
    letter-spacing: 0.04rem;
    @include choosefont();
    text-transform: uppercase;
    font-weight: 500;
    margin-bottom: $baseline/3;
    margin-top: $baseline/2;
    .design &{
      opacity:0.75;
    }
  }
  p{
    margin-bottom: 0.5rem;
    margin-left:-1px;
  }
  .detail_txt{
      padding-top: 1px;
      padding-left: 0;
      margin-left:-1px;
  }
  @media screen and (min-width: $break-normal) and (orientation : landscape) {
    padding-right: $baseline;
    @include baseline(14,18);
    .company &{
      font-weight: 400;
      @include choosefont();
    }
  }


}

$detail_position: a b c;
$position-slug: content;
$class_slug_letter:detail_letter_pos_;
$class_slug_txt:detail_txt_;
@for $i from 1 to length($detail_position) {

  .#{$class_slug_letter}#{nth($detail_position, $i+1)}{
    grid-area: #{nth($detail_position, $i+1)};
  }
  .#{$class_slug_txt}#{nth($detail_position, $i+1)}{
    grid-area: #{$position-slug}#{nth($detail_position, $i+1)};
  }  
}

</style>