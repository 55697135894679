// for testing in Safari
// import devtools from '@vue/devtools'
// for production
import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import router from './router'



Vue.config.productionTip = false



new Vue({
  el: '#app',
  router,
  components: { 
  	App   },
  template: '<App/>',
  methods:{
  	log(e) {
      console.log(e);
      }
  }
})