<template>
	<div class="container" lang="es">


		<div class="content">
			<div class="logo desktop-only"><img src="/static/img/chd_logo_positive.svg" alt=""></div>
			<h1 class="maintitle desktop-only ">
				Guía de proceso de&nbsp;licitación de&nbsp;diseño
			</h1>
			<div class="welcomeMessage hyphenate">
				<div class="logo mobile-only"><img src="/static/img/chd_logo_positive.svg" alt=""></div>
				<p>Esta guía contiene recomendaciones para fomentar procesos más eficientes y transparentes en licitaciones para proyectos de diseño. Hay recomendaciones tanto para las empresas mandantes como para las empresas de diseño.</p>
				<p>Una mala licitación puede ser un problema para la oficina de diseño y para el cliente, por eso en Chile Diseño esperamos que esta herramienta pueda generar valor y construir una cultura de respeto, transparencia y probidad que inspire a toda la industria.</p>
			</div>				

			<div class="credits desktop-only ipad-hide hyphenate"><p>El proyecto fue desarrollado por Chile Diseño con el trabajo activo de algunos de nuestros socios y colaboración de múltiples empresas mandantes. El resultado es un primer acercamiento a la mejora de dichos procesos.</p>
				<p><strong>Agradecemos Especialmente a:</strong></p>
					<p><a href="http://petitnomads.com">Petit:</a> Levantamiento de la problemática y búsqueda de soluciones</p>
					<p><a href="http://lebran.cl">Lebran:</a> Diseño de información</p>
					<p><a href="http://procorpweb.com">Procorp:</a> Diseño y desarrollo de la aplicación digital</p>
					<p><a href="http://latinotype.com">Latinotype:</a> Por las tipografías del proyecto, Inter y Hawking</p>
      		</div>
      		<!-- For ipad safari browser only -->
      		<div class="next desktop-only desktop-medium-hide standalone-hide">
      			<router-link to="/Credits">Créditos</router-link>
      		</div>
			
			<!-- titles for the process -->
		      <div class="licContainer licHeader licHeader1 display-flex flex-column flex-align-vcenter">
		      <a href="#" @click="savethis();return false;" class="install-button">
		      	<img class="invert" src="static/img/icons/icons8-add-new-filled-60.png" alt="descargar" />
		      </a>
		  	  </div>
		      <div class="licContainer licHeader licHeader2 chevron negative" style="text-align:center" 
		      ><router-link to="/Home">
		      	Comienza Aquí
		      </router-link>
		      </div>
		      <div class="licContainer licHeader licHeader3" style="text-align:center">
		      </div>

		</div>
		<!-- ios bottom nav -->

		<div class="iOSbottomnav">
			
			<router-link to="/Credits">Créditos</router-link>
			<a href="#" @click="sharethis()">Compártelo</a>
		</div>
	</div>

</template>


<script>

export default {
  name: 'Bienvenidos',
  props:{
      onError: Function,
      onSuccess: Function,
  },
  data: function(){
  	return{
	 DescriptionText:'Esta guía contiene recomendaciones para fomentar procesos más eficientes y transparentes en licitaciones para proyectos de diseño. Hay recomendaciones tanto para las empresas mandantes como para las empresas de diseño.',
	 DescriptionTitle:'Chilediseño: Guía de licitaciones de diseño',
	 shareURL: 'https://licitaciones.chilediseno.org'
	 }
  },
  components: {
  },
  methods: {
	  	sharethis(){
	  		 const data = {
	          title: this.DescriptionText,
	          text: this.DescriptionTitle,
	          url: this.shareURL
	        };
	  		if (navigator.share){
	  			navigator.share(data).then((succ) => {
	              if (succ) {
	                console.log('share worked!');
	              }
	            })
	            .catch((err) => {
	              if (onError) {
	                this.$router.push({ path: 'Share' });
	              }
	  			})
	  		}else{
	  			
	               this.$router.push({ path: 'Share' });
          		
	  		}
		},
		savethis(){
			document.getElementById("loading").style.display = "flex";
			document.getElementById("addToHome").style.display = "block";
			document.getElementById("loading-image").classList.remove("animatedslow", "infinite", "flash");

		}
	},
}
</script>	

<style lang="scss" lang="scss" scoped>
  @import '../scss/atoms/variables.scss';
  @import '../scss/atoms/grid.scss';
  @import '../scss/atoms/lic_container.scss';
  @import '../scss/atoms/chevron.scss';
  @import '../scss/atoms/install_button.scss';
  @import '../scss/templates/welcome.scss';




</style>