<template>
  <transition
  name="fade"
  mode="out-in"
  >
      <router-view/>
  </transition>
</template>

<style lang="scss">
  @import 'scss/atoms/fonts.scss';
  @import 'scss/atoms/reset.scss';
  @import 'scss/atoms/variables.scss';
  @import 'scss/atoms/typography.scss';
  @import 'scss/atoms/animation.scss';





  .licContainer {
  border-radius: 3px
  }


  ul {
    list-style: none;
    display: flex;
    padding: 0;
  }
  li {
    padding: 5px 15px 5px 0;
  }
  li a {
    text-decoration: none;
    color: black;
  }
  li a:hover {
    color: #404040;
  }
</style>

<script>
// define the height of the inner window, mainly to deal with mobiles
// Script comes from https://css-tricks.com/the-trick-to-viewport-units-on-mobile/

// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);

// We listen to the resize event
window.addEventListener('resize', () => {
  // We execute the same script as before
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});

// I did try to install lodash to handle the throttling of the event, but there were security risks added to the implementation.

</script>