import Vue from 'vue'
import Router from 'vue-router'

// Page content
import empresa01 from '@/components/empresa01'
import Welcome from '@/components/Welcome'
import Home from '@/components/Home'
import Credits from '@/components/Credits'
import Share from '@/components/Share'


// Google Analytics
import VueAnalytics from 'vue-ua'


// Fallback page
import PageNotFound from '@/components/PageNotFound'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: 'Welcome',
      component: Welcome
    },
    {
      path: '/Home',
      name: 'Proceso de Licitación de Diseño',
      component: Home
    },
        {
      path: '/Credits',
      name: 'Agradeciemientos',
      component: Credits
    },
    {
      path: '/Share',
      name: 'Compártelo',
      component: Share
    },    
    {
      path: '**',
      name: 'PageNotFound',
      component: PageNotFound
    }
  ]
})
// Google Analitics config from https://alligator.io/vuejs/google-analytics/

Vue.use(VueAnalytics, {
  // [Required] The name of your app as specified in Google Analytics.
  appName: 'Licitaciones',
  // [Required] The version of your app.
  appVersion: '002',
  // [Required] Your Google Analytics tracking ID.
  trackingId: 'UA-107588667-2',
  // If you're using vue-router, pass the router instance here.
  // vueRouter: Router,
})

